/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Walinka = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        console.log('Mentores', $('#mentores-grid .ae-no-posts').length);
        if ($('#mentores-grid .ae-no-posts').length > 0) {
          $('#mentores-title').hide();
        }
        console.log('News', $('#news-grid .ae-no-posts').length);
        if ($('#news-grid .ae-no-posts').length > 0) {
          $('#news-title').hide();
        }
        console.log('Socios', $('#socios-grid .ae-no-posts').length);
        if ($('#socios-grid .ae-no-posts').length > 0) {
          $('#socios-title').hide();
        }
        console.log('Startups', $('#startups-grid .ae-no-posts').length);
        if ($('#startups-grid .ae-no-posts').length > 0) {
          $('#startups-title').hide();
        }
        if ($('.quiero-invertir').length > 0) {
          $('.quiero-invertir').each(function () {
            var self = $(this);
            var link = self.find('a').attr('href');
            var title = self.siblings('.elementor-widget-ae-post-title').find('.ae-element-post-title').text();
            var new_link = link + encodeURIComponent(title);
            self.find('a').attr('href', new_link);
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Walinka;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
